<template>
  <div class="form-row">
    <sgv-input-select
      label="ค่าใช้จ่าย"
      placeholder="ค่าใช้จ่าย..."
      class="col-5 col-md-6"
      :options="items"
      select="id"
      v-model="formData.docConfigId"
      :validations="[
        {text: 'required!', value: $v.formData.docConfigId.$dirty && $v.formData.docConfigId.$error}
      ]">
      <template slot-scope="option">
        {{option.name}}
      </template>
    </sgv-input-select>

    <sgv-input-number
      class="col"
      placeholder="ราคา"
      v-model="formData.price"
      :precision="2"
      :validations="[
        {text: 'required!', value: $v.formData.price.$dirty && $v.formData.price.$error}
      ]"
      @keyup.enter.native="createOrder">
      <label slot="label">
        ราคา:
        <span class="pointer" @click="isVatIncluded = !isVatIncluded">
          <span v-if="isVatIncluded" class="text-warning">รวมภาษี</span>
          <span v-else>ไม่รวมภาษี</span>
        </span>
      </label>
    </sgv-input-number>

    <div class="col-auto form-group align-self-end">
      <button class="btn btn-success" @click="createOrder">
        <fa icon="plus"></fa>
      </button>
    </div>
  </div>
</template>

<script>
import round from 'lodash/round'
import { required } from 'vuelidate/lib/validators'
import {
  LIST_ORDER_EXPENSE,
  CREATE_ORDER_EXPENSE
} from './graph'

export default {
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    docId: {
      type: Number,
      required: true
    },
    vatConfigs: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      formData: {
        docConfigId: null,
        price: 0
      },
      items: [],
      isVatIncluded: true
    }
  },
  validations: {
    formData: {
      docConfigId: { required },
      price: { required },
    }
  },
  apollo: {
    items: {
      query () {
        return LIST_ORDER_EXPENSE(this.templateType)
      },
      variables () {
        return {
          docType: this.docType
        }
      },
      fetchPolicy: 'no-cache',
      result () {
        this.$nextTick(() => {
          this.setDefault()
        })
      }
    }
  },
  methods: {
    serializeInput (input) {
      const obj = {
        docConfigId: input.docConfigId,
        price: input.price
      }

      if (this.isVatIncluded) {
        const vatConfig = this.vatConfigs.find(cf => cf.isDefault)
        const vatPrice = vatConfig?.options?.vatPrice || ''

        if (vatPrice.indexOf('%') !== -1) {
          const vatRate = vatPrice.replace('%', '')
          obj.price = round(-1 * Math.abs(obj.price) / (1 + +vatRate / 100), 2)
        } else {
          this.$toasted.global.error('ไม่พบการตั้งค่าภาษี')
          return
        }
      }

      return obj
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)

      if (!input) return

      this.$apollo.mutate({
        mutation: CREATE_ORDER_EXPENSE(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(res => {
        this.$emit('updated', res.data.createOrder)
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.formData.docConfigId = null
      this.formData.price = 0
      this.$v.formData.$reset()
      const config = this.items.find(v => v.isDefault)
      if (config) this.formData.docConfigId = config.id
    }
  },
  watch: {
    'formData.docConfigId': {
      handler (v) {
        if (!v) {
          this.formData.price = ''
          this.$v.formData.$reset()
        }
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
