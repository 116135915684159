<template>
  <div class="row mt-3">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-radio
          class="col-12"
          inline
          :options="types"
          select="text"
          v-model="selected">
          <template slot-scope="option">
            {{option.text}}
          </template>
        </sgv-input-radio>
      </div>
    </div>

    <div class="col-12" v-for="t in types" :key="t.text">
      <component
        v-if="selected === t.text"
        :is="t.value"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :formData="formData"
        :url="t.url"
        :filename="t.filename">
      </component>
    </div>
  </div>
</template>

<script>
import DetailPrintPdf from '@/views/doc_core/components/PrintPdf'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    contact: {
      type: Object
    }
  },
  data () {
    return {
       selected: 'ใบเสร็จรับเงิน'
    }
  },
  computed: {
    types () {
      const arr = [
        {
          text: 'ใบเสร็จรับเงิน',
          value: 'DetailPrintPdf',
          url: `/doc/${this.docType}/${this.docId}/print`,
          filename: `${this.formData.code}.pdf`
        }
      ]

      if (this.formData.closedAt) {
        arr.push({
          text: 'ใบแนบบัญชี',
          value: 'DetailPrintPdf',
          url: `/doc/${this.docType}/${this.docId}/print/cover`,
          filename: `${this.formData.code}-cover.pdf`
        })
      }

      return arr
    }
  },
  components: {
    DetailPrintPdf
  }
}
</script>

<style lang="css" scoped>
</style>
