<template>
  <div class="mt-3">
    <sgv-table
      :items="orders"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options"
      :toolbars="toolbars">

      <template slot="option" v-if="options.toolbar === 'other'">
        <div class="mb-3">
          <DetailOrderExpenseInput
            ref="orderExpense"
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :vatConfigs="vatConfigs"
            @updated="orderList">
          </DetailOrderExpenseInput>

          <DetailOrderVatPriceInput
            ref="orderVatPrice"
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :orders="orders"
            @configs="vatConfigs = $event"
            @updated="orderList">
          </DetailOrderVatPriceInput>

          <DetailOrderWithholdingPriceInput
            ref="orderWithholdingPrice"
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :orders="orders"
            @updated="orderList">
          </DetailOrderWithholdingPriceInput>

          <DetailOrderPaymentInput
            ref="orderPayment"
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :orders="orders"
            @updated="orderList">
          </DetailOrderPaymentInput>
        </div>
      </template>

      <template slot-scope="row">
        <DetailOrderSubItem
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :row="row"
          :canDestroy="canDestroy"
          @updated="orderDeleted">
        </DetailOrderSubItem>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import round from 'lodash/round'
import DetailOrderExpenseInput from './DetailOrderExpenseInput'
import DetailOrderVatPriceInput from './DetailOrderVatPriceInput'
import DetailOrderWithholdingPriceInput from './DetailOrderWithholdingPriceInput'
import DetailOrderPaymentInput from './DetailOrderPaymentInput'
import DetailOrderSubItem from './DetailOrderSubItem.vue'
import {
  LIST_ORDER,
  WATCH_DOC_UPDATED,
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      orders: [],
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      vatConfigs: []
    }
  },
  computed: {
    headers () {
      return [
        {text: 'id', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      if (this.formData.approvedAt) return []

      const arr = [
        {value: 'other', icon: 'plus', class: 'text-success'}
      ]

      return arr
    },
    ledgersSum () {
      return this.orders.reduce((t,v) => {
        const x = t + v.ledgers.reduce((sub, ledger) => {
          return sub + Number(ledger.amount)
        }, 0)
        return round(x, 2)
      }, 0)
    },
    canDestroy () {
      const types = ['other']
      return !this.formData.closedAt && types.includes(this.options.toolbar)
    }
  },
  apollo: {
    $subscribe: {
      docUpdated: {
        query () {
          return WATCH_DOC_UPDATED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result () {
          this.orderList()
        }
      },
    },
  },
  methods: {
    orderList () {
      this.$apollo.query({
        query: LIST_ORDER(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        const orders = res.data.orders
        const expenses = orders.filter(v => ["expense", "deposit"].includes(v.type))
        const vatPrices = orders.filter(v => v.type === 'vatPrice')
        const withholdings = orders.filter(v => v.type === 'withholdingPrice')
        const payments = orders.filter(v => v.type === 'payment')
        const netPrices = orders.filter(v => v.type === 'netPrice')
        const changeErrors = orders.filter(v => v.type === 'changeError')
        this.orders = [
          ...expenses,
          ...vatPrices,
          ...withholdings,
          ...payments,
          ...changeErrors,
          ...netPrices,
        ]
      })
      .catch(() => {
        this.orders = []
      })
    },
    orderDeleted () {
      this.orderList()
    }
  },
  watch: {
    'formData.approvedAt': {
      handler () {
        this.options.toolbar = null
      }
    }
  },
  created () {
    this.orderList()
  },
  components: {
    DetailOrderExpenseInput,
    DetailOrderVatPriceInput,
    DetailOrderWithholdingPriceInput,
    DetailOrderPaymentInput,
    DetailOrderSubItem
  }
}
</script>

<style lang="css" scoped>
</style>
